<template>
  <div ref="element">
    <title-bar :title-stack="titleStack"/>
    <section class="mt-3 p-5">
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <button
              :disabled="menuAccess('/project/input')"
              @click="createPenawaran()"
              class="button is-small is-link is-outlined"
            >
              <span class="icon is-small">
                <b-icon icon="plus" />
              </span>
              <span>Buat Penawaran</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- TABS MENU -->
    <div class="columns mt-5">
      <div class="column">
        <div class="tabs is-centered is-boxed">
          <ul>
            <li :class="{'is-active' : activeTab === 0}">
              <a @click="selectTab(0)">
                <span>Penawaran Ditinjau Client</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 1}">
              <a @click="selectTab(1)">
                <span>Penawaran Disetujui Client</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 2}">
              <a @click="selectTab(2)">
                <span>Penawaran Ditolak</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /TABS MENU -->

    <div v-show="activeTab===0" class="mt-3">
      <card-component class="has-table has-mobile-sort-spaced">
        <OfferTablePending v-if="offersPending !== null" :pending="offersPending"/>
      </card-component>
    </div>
    <div v-show="activeTab===1" class="mt-3">
      <card-component class="has-table has-mobile-sort-spaced">
        <OfferTableApproved v-if="offerApproved !== null" :approved="offerApproved"/>
      </card-component>
    </div>
    <div v-show="activeTab===2">
      <card-component class="has-table has-mobile-sort-spaced">
        <OfferTableRejected v-if="offersRejected !== null" :rejected="offersRejected"/>
      </card-component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import guard from '../../routerGuard'
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import OfferTablePending from '../../components/TablePenawaran/OfferTablePending.vue'
import OfferTableApproved from '../../components/TablePenawaran/OfferTableApproved.vue'
import OfferTableRejected from '../../components/TablePenawaran/OfferTableRejected.vue'

export default {
  name: 'PenawaranList',
  data () {
    return {
      activeTab: 0
    }
  },
  components: {
    TitleBar,
    CardComponent,
    OfferTableApproved,
    OfferTableRejected,
    OfferTablePending
  },
  watch: {},
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      offerApproved: 'penawaran/getListApproved',
      offersPending: 'penawaran/getListPending',
      offersRejected: 'penawaran/getListRejected'
    }),
    titleStack () {
      return [
        'Admin',
        'Penawaran'
      ]
    }
  },
  methods: {
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    selectTab (val) {
      this.activeTab = val
    },
    createPenawaran () {
      this.$router.push('/penawaran/form-penawaran')
    },
    listOffer () {
      const data = { url: this.url }

      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element
      })
      setTimeout(() => {
        loadingComponent.close()
        this.$store.dispatch('penawaran/offerListApproved', data)
        this.$store.dispatch('penawaran/offerListRejected', data)
        this.$store.dispatch('penawaran/offerListPending', data)
      }, 2 * 1000)
    }
  },
  created () {
    this.listOffer()
  }
}
</script>

<style lang="scss" scoped>
</style>
