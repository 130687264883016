<template>
  <div>
    <modal-box :is-active="isModalActive" :trash-object-name="trashObjectName" @confirm="trashConfirm" @cancel="trashCancel"/>
    <b-table
      :per-page="perPage"
      :data="isEmpty ? [] : offersPending"
      :current-page.sync="currentPage"
      :pagination-position="paginationPosition"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :paginated="isPaginated">

      <b-table-column label="#ID" field="id" v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.id">{{ props.row.id }}</small>
      </b-table-column>
      <b-table-column label="Nomor Penawaran" field="reference_code" v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.reference_code">{{ props.row.reference_code }}</small>
      </b-table-column>
      <b-table-column label="Client" v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.client_name">{{ props.row.client_name }}</small>
      </b-table-column>
      <b-table-column label="Created By" field="created_by" v-slot="props">
        {{ props.row.created_by }}
      </b-table-column>
      <b-table-column label="Status" v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.status">
          <span class="tag is-warning">
            <p v-if="props.row.status === 0"> PENDING </p>
          </span>
        </small>
      </b-table-column>
      <b-table-column label="Action" custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-centered">
          <div class="button is-small is-primary" @click="detailOnClick(props.row.id)">
            <b-icon icon="account-edit" size="is-small"/>
          </div>
        </div>
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template>
            <p>
              <b-icon icon="alert-outline" size="is-large"/>
            </p>
            <p>No data here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapGetters } from 'vuex'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'OfferTablePending',
  components: { ModalBox },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: true,
      currentPage: 1,
      perPage: 5,
      paginationPosition: 'bottom',
      isEmpty: false
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    ...mapGetters({
      offersPending: 'penawaran/getListPending'
    })
  },
  props: { initData: Array },
  watch: {
    initData (newVal) {
      if (newVal.length > 0) {
        this.offersPending = newVal
      } else {
        this.isEmpty = true
      }
    }
  },
  methods: {
    detailOnClick (v) {
      this.$router.push({
        path: `/penawaran/detail/${v}/Pending`
      })
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    }
  },
  mounted () {},
  created () {}
}
</script>
